import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    referralErrors: null,
    referralError: null,
    pagination: {
      currentPage: 1,
      total: 1,
    },
  },
  getters: {
    getReferralErrors: (state) => state.referralErrors,
    getReferralError: (state) => state.referralError,
    getPagination: (state) => state.pagination,
  },
  mutations: {
    setReferralErrors(state, errors) {
      state.referralErrors = errors.data.data;
      state.pagination.currentPage = errors.data.current_page;
      state.pagination.total = errors.data.last_page;
    },
    setReferralError(state, errors) {
      state.referralError = errors;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentPackagePage(state, currentPage) {
      state.packagePagination.currentPage = currentPage;
    },
  },
  actions: {
    async fetchReferralErrors({ commit, state }) {
      const response = await http.get(
        "admin/referrals/sport-referrals-errors",
        {
          params: {
            page: state.pagination.currentPage,
          },
        }
      );
      const links = response;
      commit("setReferralErrors", links);
    },
    async fetchDetail({ commit }, { id }) {
      const response = await http.get(
        `admin/referrals/detail-referral-error/${id}`
      );
      commit("setReferralError", response.data);
    },
  },
};
