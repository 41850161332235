import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    patients: [],
    errors: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    search: "",
    currentPatient: {
      first_name: "",
      last_name: "",
      phone_number: "",
      identity_card: "",
      pesel: "",
      birth_date: "",
      day: "",
      month: "",
      year: "",
      gender: "",
    },
    currentPatientStats: {
      referrals: {
        issued: 0,
        paid: 0,
        canceled: 0,
        completed: 0,
      },
      total_price: 0,
    },
  },
  getters: {
    getPatients: (state) => state.patients,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getSearch: (state) => state.search,
    getCurrentPatient: (state) => state.currentPatient,
    getCurrentPatientStats: (state) => state.currentPatientStats,
    getPatientById: (state) => (id) => {
      return state.patients.find((patient) => patient.id === id);
    },
  },
  mutations: {
    setPatients(state, patients) {
      state.patients = patients.data;
      state.pagination.currentPage = patients.meta.current_page;
      state.pagination.total = patients.meta.last_page;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    addError(state, { key, error }) {
      state.errors = {
        ...state.errors,
        [key]: error,
      };
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSearch(state, search) {
      state.search = search;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentPatient(state, currentPatient) {
      state.currentPatient = { ...currentPatient };
    },
    setCurrentPatientStats(state, stats) {
      state.currentPatientStats = stats;
    },
    setCurrentPatientValue(state, currentPatient) {
      Object.assign(state.currentPatient, currentPatient);
    },
    setBirthDate(state, currentPatient) {
      Object.assign(state.currentPatient, currentPatient);
      const { year, day, month } = state.currentPatient;
      state.currentPatient.birth_date = `${day}.${month}.${year}`;
    },
    clearCurrentPatient(state) {
      Object.keys(state.currentPatient).forEach(
        (key) => (state.currentPatient[key] = "")
      );
    },
    updatePatient(state, user) {
      const index = state.patients.findIndex(
        (patient) => patient.id === user.id
      );

      if (index >= 0) {
        state.patients[index] = user;
      }
    },
    clearCurrentPatientStats(state) {
      Object.keys(state.currentPatientStats.referrals).forEach(
        (key) => (state.currentPatientStats.referrals[key] = 0)
      );
      state.currentPatientStats.total_price = 0;
    },
    clearSearch(state) {
      state.search = "";
    },
    clearPatients(state) {
      state.patients = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
  },
  actions: {
    async fetchPatients({ commit, state }, sortBy) {
      const response = await http.get("doctor/patients", {
        params: {
          page: state.pagination.currentPage,
          search: state.search,
          ...sortBy,
        },
      });
      const patients = response.data;
      commit("setPatients", patients);
    },
    async savePatient({ commit }, patient) {
      return await http
        .post(`/doctor/patients/store`, patient)
        .then(({ data }) => {
          commit("clearErrors");
          commit("clearCurrentPatient");
          return data.user;
        })
        .catch(({ response }) => {
          if (response && response.status === 422) {
            commit("setErrors", response.data.errors);
            return false;
          }
          if (response && response.status === 403) {
            return null;
          }
        });
    },
    async assignUserToDoctors(_, userData) {
      return await http
        .post(`/doctor/patients/assign-user`, userData)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.forbidden",
              duration: 5000,
            });
          }
        });
    },
    async updatePatient({ commit, state }, referralId = null) {
      await http
        .put(
          referralId
            ? `/referrals/update-patient/${state.currentPatient.id}/${referralId}`
            : `/doctor/patients/update/${state.currentPatient.id}`,
          state.currentPatient
        )
        .then(({ data }) => {
          commit("clearErrors");
          if (referralId) {
            commit("referrals/setCurrentReferralPatient", data.user, {
              root: true,
            });
          } else {
            commit("updatePatient", data.user);
            commit("setCurrentPatient", {
              ...data.user,
              phone_is_valid: true,
            });
          }

          if (referralId) {
            notify({
              type: "success",
              text: "messages.edit_amd_send_seferral.success",
              duration: 5000,
            });
          } else {
            notify({
              type: "success",
              text: "messages.edit.success",
              duration: 5000,
            });
          }

          commit("modals/closeModal", "createPatient", { root: true });
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            commit("modals/closeModal", "createPatient", { root: true });
            notify({
              type: "danger",
              text: "messages.permission.forbidden",
              duration: -1,
              ignoreDuplicates: true,
            });
            return;
          } else {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            notify({
              type: "danger",
              text: "messages.edit.failure",
              duration: 5000,
            });
          }
        });
    },
    async fetchCurrentPatient(
      { commit, rootState },
      { id, time = "current_month", date_range = null, filters, sortBy }
    ) {
      return await http
        .get(`doctor/patients/${id}`, {
          params: {
            page: rootState.referrals.pagination.currentPage,
            time: time,
            date_range: date_range,
            ...filters,
            ...sortBy,
          },
        })
        .then(({ data }) => {
          commit("setCurrentPatient", {
            ...data.patient,
            phone_is_valid: true,
          });
          commit("setCurrentPatientStats", data.stats);
          commit("referrals/setReferrals", data.referrals, { root: true });
          commit("referrals/setPriceRange", data.price_range, {
            root: true,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
  },
};
