import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    access: [],
  },
  getters: {
    getAllAccess: (state) => state.access,
  },
  mutations: {
    setAllAccess(state, access) {
      state.access = access;
    },
  },
  actions: {
    async fetchAccessList({ commit }) {
      const response = await http.get("/gatekeeper/admin/access");
      const access = response.data.access;
      commit("setAllAccess", access);
    },
  },
};
