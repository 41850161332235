import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    admins: [],
    adminsCount: null,
    errors: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    statuses: [
      {
        id: 1,
        name: "active",
      },
      {
        id: 3,
        name: "blocked",
      },
    ],
    search: "",
    currentAdmin: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      status: "",
      created_at: "",
      access: [],
    },
  },
  getters: {
    getAdmins: (state) => state.admins,
    getAdminsCount: (state) => state.adminsCount,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getSearch: (state) => state.search,
    getCurrentAdmin: (state) => state.currentAdmin,
    getStatuses: (state) => state.statuses,
    getStatusById: (state) => (id) => {
      return state.statuses.find((status) => status.id === id);
    },
    getAdminById: (state) => (id) => {
      return state.admins.find((admin) => admin.id === id);
    },
  },
  mutations: {
    setAdmins(state, admins) {
      state.admins = admins.data;
      state.pagination.currentPage = admins.meta.current_page;
      state.pagination.total = admins.meta.last_page;
    },
    setAdminsCount(state, count) {
      state.adminsCount = count;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    addError(state, { key, error }) {
      state.errors = {
        ...state.errors,
        [key]: error,
      };
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSearch(state, search) {
      state.search = search;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentAdmin(state, currentAdmin) {
      state.currentAdmin = currentAdmin;
    },
    setCurrentAdminValue(state, currentAdmin) {
      Object.assign(state.currentAdmin, currentAdmin);
    },
    setCurrentAdminAccess(state, access) {
      state.currentAdmin.access = access;
    },
    clearCurrentAdmin(state) {
      Object.keys(state.currentAdmin).forEach(
        (key) => (state.currentAdmin[key] = "")
      );
    },
    clearSearch(state) {
      state.search = "";
    },
    clearAdmins(state) {
      state.admins = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
  },
  actions: {
    async fetchAdmins({ commit, state }, { filters, sortBy }) {
      const { data } = await http.get("gatekeeper/admin", {
        params: {
          page: state.pagination.currentPage,
          search: state.search,
          ...filters,
          ...sortBy,
        },
      });
      const admins = data.admins;
      commit("setAdmins", admins);
      commit("setAdminsCount", admins.meta.total);
    },

    async changeStatus(_, data) {
      return await http
        .post(`/gatekeeper/admin/${data.id}/change-status`, {
          status: data.status,
        })
        .then(() => {
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            notify({
              type: "danger",
              text: "messages.save.failure",
              duration: 5000,
            });
        });
    },

    async saveAdmin({ commit, state }) {
      return await http
        .post(`/gatekeeper/admin/store`, state.currentAdmin)
        .then(() => {
          commit("clearErrors");
          commit("clearCurrentAdmin");
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
          router.push({ name: "AdminsList" });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: "messages.save.failure",
            duration: 5000,
          });
        });
    },

    async updateAdmin({ commit, state }, id) {
      await http
        .post(`/gatekeeper/admin/${id}/update`, state.currentAdmin)
        .then(() => {
          commit("clearErrors");
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
          router.push({ name: "AdminsList" });
          commit("clearCurrentAdmin");
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
            return;
          }
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: "messages.edit.failure",
            duration: 5000,
          });
        });
    },

    async getAdmin({ commit }, id) {
      return await http
        .get(`gatekeeper/admin/${id}`)
        .then(({ data }) => {
          commit("setCurrentAdmin", data.admins);
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
  },
};
