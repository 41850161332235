/* eslint-disable no-unused-vars */
import http from "@/utils/axios";
import store from "@/store";
import router from "@/router/index.js";
import { notify } from "@kyvg/vue3-notification";

const prepareCart = () => {
  let cart = [];
  try {
    if (sessionStorage.getItem("cart"))
      cart = JSON.parse(sessionStorage.getItem("cart"));
  } catch (e) {
    cart = [];
  }
  return cart;
};

export default {
  namespaced: true,
  state: {
    cart: prepareCart(),
    tmpCart: [],
    require_additional: null,
    errors: [],
    totalPrice: 0,
    commission: 0,
    patientDiscount: 0,
    patientDiscountPercent: 0,
    covid_nursing_service: null,
    nursing_service: null,
    isSelectedBundles: true,
    isSelectedOwnPackages: false,
    facility: null,
  },
  getters: {
    getCart: (state) => state.cart,
    getTmpCart: (state) => state.tmpCart,
    getPatientsExaminations: (state) => {
      return state.cart.filter((item) => !item.added_by_doctor);
    },
    getExaminationIds: (state) => state.cart.map((item) => item.id),
    getTmpExaminationIds: (state) => state.tmpCart.map((item) => item.id),
    getExaminationAddedByDoctor: (state) => (id) => {
      const item = state.cart.find((item) => item.id === id);
      if (typeof item !== "undefined") {
        return item.added_by_doctor;
      }
      return 0;
    },
    getErrors: (state) => state.errors,
    getTotalPrice: (state) => state.totalPrice,
    getCommission: (state) => state.commission,
    getCartAddedByDoctor: (state) =>
      state.cart.filter((item) => item.added_by_doctor == 1),
    getCartAddedByPatient: (state) =>
      state.cart.filter((item) => item.added_by_doctor == 0),
    getRequireAdditional: (state) => state.require_additional,
    getPatientDiscount: (state) => state.patientDiscount,
    getIsSelectedBundles: (state) => state.isSelectedBundles,
    getFacility: (state) => state.facility,
    hasUnavilable: (state) =>
      state.cart.filter((item) => !item.available).length > 0,
  },
  mutations: {
    setCart(store, cart) {
      store.cart = cart;
    },
    setTmpCart(store, cart) {
      store.tmpCart = cart;
    },
    setNursingServices(state, services) {
      state.covid_nursing_service = services[0];
      state.nursing_service = services[1];
    },
    setAdditionalServices(state, services) {
      if (services.require_nursing_services) {
        state.require_additional = "upiel";
      } else if (services.require_covid_nursing_services) {
        state.require_additional = "upielc";
      }
    },
    clearAdditionalServices(state) {
      state.require_additional = null;
    },
    initAdditionalServices(state) {
      const nurse = state.cart.filter((item) => item.require_nursing_services);
      const covid = state.cart.filter(
        (item) => item.require_covid_nursing_services
      );
      if (nurse.length > 0) state.require_additional = "upiel";
      else if (covid.length > 0) state.require_additional = "upielc";
      else if (!nurse.length && !covid.length) state.require_additional = null;
    },
    clearCart(store) {
      store.cart = [];
    },
    clearFacility(store) {
      store.facility = null;
    },
    clearAll(state) {
      state.cart = [];
      state.totalPrice = 0;
      state.commission = 0;
      state.errors = [];
      state.require_additional = null;
      sessionStorage.removeItem("cart");
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    setPriceAndCommission(state, commissionPercent) {
      const totalPrice = state.cart.reduce(
        (accumulator, current) => accumulator + current.price * 1,
        0
      );
      let additionalPrice = 0;
      state.commission = (totalPrice * (commissionPercent / 100)).toFixed(2);
      if (state.require_additional === "upiel")
        additionalPrice = state.nursing_service.price * 1;
      if (state.require_additional === "upielc")
        additionalPrice = state.covid_nursing_service.price * 1;
      state.totalPrice = totalPrice + additionalPrice;
      state.patientDiscount = (
        state.totalPrice *
        (state.patientDiscountPercent / 100)
      ).toFixed(2);
    },
    setPatientDiscountPercent(state, patientDiscountPercent) {
      state.patientDiscountPercent = patientDiscountPercent;
    },
    setIsSelectedBundles(state, isSelected) {
      state.isSelectedBundles = isSelected;
    },
    setFacility(state, facility) {
      state.facility = { ...facility };
    },
  },
  actions: {
    manageCartPackage({ commit, rootGetters }, examinations) {
      const cart = prepareCart();
      const cartExaminations = [];

      examinations.forEach(function (examination) {
        if (cart.findIndex((e) => e.id === examination.id) < 0) {
          let userRole = rootGetters["user/getCurrentUserRole"];
          cart.push({
            id: examination.id,
            name: examination.name,
            price: examination.price,
            package: examination.package,
            added_by_doctor: userRole === "patient" ? 0 : 1,
            require_nursing_services: examination.require_nursing_services,
            require_covid_nursing_services:
              examination.require_covid_nursing_services,
            available: true,
          });
          commit("setAdditionalServices", {
            require_nursing_services: examination.require_nursing_servicesVal,
            require_covid_nursing_services:
              examination.require_covid_nursing_servicesVal,
          });
          sessionStorage.setItem("cart", JSON.stringify(cart));
          commit("setCart", cart);
          commit("initAdditionalServices");
          commit(
            "setPriceAndCommission",
            rootGetters["user/getCurrentUserCommissionPercent"]
          );
        }
      });
    },
    manageCart({ commit, rootGetters }, { examination, isActive }) {
      const cart = prepareCart();

      if (isActive) {
        const index = cart.findIndex((item) => item.id === examination.id);
        cart.splice(index, 1);
      } else {
        let userRole = rootGetters["user/getCurrentUserRole"];
        cart.push({
          id: examination.id,
          name: examination.name,
          price: examination.price,
          package: examination.package,
          added_by_doctor: userRole === "patient" ? 0 : 1,
          require_nursing_services: examination.require_nursing_services,
          require_covid_nursing_services:
            examination.require_covid_nursing_services,
          available: true,
        });
        commit("setAdditionalServices", {
          require_nursing_services: examination.require_nursing_services,
          require_covid_nursing_services:
            examination.require_covid_nursing_services,
        });
      }

      sessionStorage.setItem("cart", JSON.stringify(cart));
      commit("setCart", cart);
      commit("initAdditionalServices");
      commit(
        "setPriceAndCommission",
        rootGetters["user/getCurrentUserCommissionPercent"]
      );
    },
    manageCartFromSearchbar(
      { commit, rootGetters },
      { examination, isActive }
    ) {
      const cart = prepareCart();
      if (isActive) {
        const index = cart.findIndex((item) => item.id === examination.id);
        cart.splice(index, 1);
      } else {
        let userRole = rootGetters["user/getCurrentUserRole"];
        cart.push({
          id: examination.id,
          name: examination.name,
          price: examination.price,
          package: examination.is_bundle,
          added_by_doctor: userRole === "patient" ? 0 : 1,
          require_nursing_services: examination.require_nursing_services,
          require_covid_nursing_services:
            examination.require_covid_nursing_services,
          available: true,
        });
        commit("setAdditionalServices", {
          require_nursing_services: examination.require_nursing_services,
          require_covid_nursing_services:
            examination.require_covid_nursing_services,
        });
      }
      sessionStorage.setItem("cart", JSON.stringify(cart));
      commit("setCart", cart);
      commit("initAdditionalServices");
      commit(
        "setPriceAndCommission",
        rootGetters["user/getCurrentUserCommissionPercent"]
      );
    },
    async fetchReferral({ commit, rootGetters }, id) {
      return await http
        .get(`/referrals/edit/${id}`)
        .then(({ data }) => {
          sessionStorage.setItem(
            "cart",
            JSON.stringify(
              data.referral.examinations.filter(
                (examination) => !examination.is_additional
              )
            )
          );
          commit("setFacility", data.referral.facility);
          commit("referrals/setCurrentReferral", data.referral, { root: true });
          commit(
            "user/setCurrentUserCommissionPercent",
            data.referral.doctor_commission_percent,
            {
              root: true,
            }
          );
          commit(
            "setCart",
            data.referral.examinations.filter(
              (examination) => !examination.is_additional
            )
          );
          commit("initAdditionalServices");
          commit(
            "setPatientDiscountPercent",
            data.referral.patient_discount_percent
          );
          commit(
            "setPriceAndCommission",
            rootGetters["user/getCurrentUserCommissionPercent"]
          );
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
    async saveReferral({ commit, state }, { user, historicalData }) {
      return await http
        .post(`/referrals/store/${user.id}`, {
          cart: state.cart,
          require_additional: state.require_additional,
          facility_id: state.facility.id,
          accessToHistoricalData: historicalData,
        })
        .then(() => {
          sessionStorage.removeItem("cart");
          commit("clearCart");
          commit("clearFacility");
          commit("clearErrors");
          store.commit("modals/closeModal", "createPatient");
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
          router.push({ name: "DoctorReferralHistory" });
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            store.commit("modals/closeModal", "createPatient");
            notify({
              type: "danger",
              text: "messages.permission.forbidden",
              duration: -1,
              ignoreDuplicates: true,
            });
          } else {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            notify({
              type: "danger",
              text: "messages.edit.failure",
              duration: 5000,
            });
          }
        });
    },
    async updateReferral({ commit, state }, id) {
      return await http
        .put(`/referrals/update/${id}`, {
          cart: state.cart,
          require_additional: state.require_additional,
          facility_id: state.facility.id,
        })
        .then(() => {
          store.commit("modals/closeModal", "referralEditWarning");
          router.push({ name: "DoctorReferralHistory" });
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.forbidden",
              duration: -1,
              ignoreDuplicates: true,
            });
          } else {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            notify({
              type: "danger",
              text: "messages.edit.failure",
              duration: 5000,
            });
          }
        });
    },

    async payForReferral({ commit, state }, id) {
      return await http
        .post(`/referrals/pay/${id}`, {
          cart: state.cart,
        })
        .then(({ data }) => {
          window.open(data.url, "_self");
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          let message = "";
          switch (response.status) {
            case 404:
              message = "messages.payment_token.failure";
              break;
            case 403:
              message = "messages.permission.failure";
              break;
            default:
              message = "messages.edit.failure";
              break;
          }
          notify({
            type: "danger",
            text: message,
            duration: 5000,
          });
        });
    },

    async fetchUpdatedServicesPrices(state, payload) {
      return await http.post("updated/examinations-data", payload);
    },
    async updateTmpExaminationsPrices(
      { state, getters, commit, dispatch },
      facility_id
    ) {
      const { data } = await dispatch("fetchUpdatedServicesPrices", {
        examinaitons: getters.getTmpExaminationIds,
        facility_id,
      });

      const tmpCart = [];
      state.tmpCart.forEach((examinaiton) => {
        const newExamination = data.examinations[examinaiton.id];
        tmpCart.push({
          ...examinaiton,
          id: newExamination.id,
          price: newExamination.price,
          available: newExamination.isAvailable,
        });
      });

      commit("setTmpCart", tmpCart);
    },
    async updateExaminationsPrices(
      { state, getters, commit, dispatch },
      facility_id
    ) {
      const { data } = await dispatch("fetchUpdatedServicesPrices", {
        examinaitons: getters.getExaminationIds,
        facility_id,
      });

      const tmpCart = [];
      state.cart.forEach((examinaiton) => {
        const newExamination = data.examinations[examinaiton.id];
        tmpCart.push({
          ...examinaiton,
          id: newExamination.id,
          price: newExamination.price,
          available: newExamination.isAvailable,
        });
      });

      sessionStorage.setItem("cart", JSON.stringify(tmpCart));
      commit("setCart", tmpCart);
    },
  },
};
