import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    gatekeepers: [],
    gatekeepersCount: null,
    errors: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    statuses: [
      {
        id: 1,
        name: "active",
      },
      {
        id: 3,
        name: "blocked",
      },
    ],
    search: "",
    currentGatekeeper: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      status: "",
      created_at: "",
      phone_is_valid: "",
      national_number: "",
      country_code: "",
      country: "",
    },
  },
  getters: {
    getGatekeepers: (state) => state.gatekeepers,
    getGatekeepersCount: (state) => state.gatekeepersCount,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getSearch: (state) => state.search,
    getCurrentGatekeeper: (state) => state.currentGatekeeper,
    getStatuses: (state) => state.statuses,
    getStatusById: (state) => (id) => {
      return state.statuses.find((status) => status.id === id);
    },
    getGatekeeperById: (state) => (id) => {
      return state.gatekeepers.find((gatekeeper) => gatekeeper.id === id);
    },
  },
  mutations: {
    setGatekeepers(state, gatekeepers) {
      state.gatekeepers = gatekeepers.data;
      state.pagination.currentPage = gatekeepers.meta.current_page;
      state.pagination.total = gatekeepers.meta.last_page;
    },
    setGatekeepersCount(state, count) {
      state.gatekeepersCount = count;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    addError(state, { key, error }) {
      state.errors = {
        ...state.errors,
        [key]: error,
      };
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSearch(state, search) {
      state.search = search;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentGatekeeper(state, currentGatekeeper) {
      state.currentGatekeeper = currentGatekeeper;
    },
    setCurrentGatekeeperValue(state, currentGatekeeper) {
      Object.assign(state.currentGatekeeper, currentGatekeeper);
    },
    clearCurrentGatekeeper(state) {
      Object.keys(state.currentGatekeeper).forEach(
        (key) => (state.currentGatekeeper[key] = "")
      );
    },
    clearSearch(state) {
      state.search = "";
    },
    clearGatekeepers(state) {
      state.gatekeepers = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
  },
  actions: {
    async fetchGatekeepers({ commit, state }, { filters, sortBy }) {
      const { data } = await http.get("gatekeeper", {
        params: {
          page: state.pagination.currentPage,
          search: state.search,
          ...filters,
          ...sortBy,
        },
      });
      const gatekeepers = data.gatekeepers;
      commit("setGatekeepers", gatekeepers);
      commit("setGatekeepersCount", gatekeepers.meta.total);
    },

    async changeStatus(_, data) {
      return await http
        .post(`/gatekeeper/${data.id}/change-status`, {
          status: data.status,
        })
        .then(() => {
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            notify({
              type: "danger",
              text: "messages.save.failure",
              duration: 5000,
            });
        });
    },

    async saveGatekeeper({ commit, state }) {
      return await http
        .post(`/gatekeeper/store`, state.currentGatekeeper)
        .then(() => {
          commit("clearErrors");
          commit("clearCurrentGatekeeper");
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
          router.push({ name: "GatekeepersList" });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: "messages.save.failure",
            duration: 5000,
          });
        });
    },

    async updateGatekeeper({ commit, state }, id) {
      await http
        .post(`/gatekeeper/${id}/update`, state.currentGatekeeper)
        .then(() => {
          commit("clearErrors");
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
          router.push({ name: "GatekeepersList" });
          commit("clearCurrentGatekeeper");
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
            return;
          }
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: "messages.edit.failure",
            duration: 5000,
          });
        });
    },

    async getGatekeeper({ commit }, id) {
      return await http
        .get(`gatekeeper/${id}`)
        .then(({ data }) => {
          commit("setCurrentGatekeeper", data.gatekeepers);
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
  },
};
