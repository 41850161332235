import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    errors: [],
    reportLinks: null,
    reports: null,
    pagination: {
      currentPage: 1,
      total: 1,
    },
    loadingReports: false,
  },
  getters: {
    getErrors: (state) => state.errors,
    getReportLinks: (state) => state.reportLinks,
    getReportsList: (state) => state.reports,
    getPagination: (state) => state.pagination,
    isLoadingReports: (state) => state.loadingReports,
  },
  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    setReportLinks(state, reportLinks) {
      state.reportLinks = reportLinks.data.links.data;
      state.pagination.currentPage = reportLinks.data.links.current_page;
      state.pagination.total = reportLinks.data.links.last_page;
    },
    setReportsList(state, list) {
      state.reports = list.data;
      state.pagination.currentPage = list.meta.current_page;
      state.pagination.total = list.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentPackagePage(state, currentPage) {
      state.packagePagination.currentPage = currentPage;
    },
    setLoadingReports(state, isLoading) {
      state.loadingReports = isLoading;
    },
  },
  actions: {
    async downloadReport({ commit }, { url, params }) {
      commit("setLoadingReports", true);
      return await http({
        url: url,
        method: "GET",
        params,
        responseType: "blob",
      })
        .then(() => {
          commit("clearErrors");
          commit("setLoadingReports", false);
          notify({
            type: "success",
            text: "messages.generate.success",
            duration: 5000,
          });
        })
        .catch(async ({ response }) => {
          commit("setLoadingReports", false);
          if (response && response.status === 422) {
            commit("setErrors", JSON.parse(await response.data.text()).errors);
            notify({
              type: "danger",
              text: "messages.generate.failure",
              duration: 5000,
            });
          } else if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
          } else {
            notify({
              type: "danger",
              text: "messages.generate.failure",
              duration: 5000,
            });
          }
        });
    },
    async fetchReportLinks({ commit, state }) {
      const response = await http.get("admin/reports/daily-report-links", {
        params: {
          page: state.pagination.currentPage,
        },
      });
      const links = response;
      commit("setReportLinks", links);
    },
    async fetchReportsList({ commit, state }) {
      const response = await http.get("admin/reports", {
        params: {
          page: state.pagination.currentPage,
        },
      });
      const list = response;
      commit("setReportsList", list.data);
    },
  },
};
