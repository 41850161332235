import http from "@/utils/axios";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    nameCategory: [],
    examinations: [],
    packages: [],
    popularExaminations: [],
    examinationsCategories: [],
    packagesCategories: [],
    errors: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    packagePagination: {
      currentPage: 1,
      total: 1,
    },
    doctorPackagePagination: {
      currentPage: 1,
      total: 1,
    },
    search: "",
    services: {
      covid_nursing_service: null,
      nursing_service: null,
    },
    doctorPackages: [],
    doctorBundle: null,
  },
  getters: {
    getNameCategory: (state) => state.nameCategory,
    getExaminations: (state) => state.examinations,
    getPackages: (state) => state.packages,
    getPopularExaminations: (state) => state.popularExaminations,
    getExaminationsCategories: (state) => state.examinationsCategories,
    getPackagesCategories: (state) => state.packagesCategories,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getPackagePagination: (state) => state.packagePagination,
    getDoctorPackagePagination: (state) => state.doctorPackagePagination,
    getNursingServices: (state) => state.services,
    getDoctorPackages: (state) => state.doctorPackages,
    getDoctorBundle: (state) => state.doctorBundle,
  },
  mutations: {
    setExaminations(
      state,
      { nameCategory, examinations, packages, doctorBundle }
    ) {
      state.nameCategory = nameCategory;
      state.examinations = examinations.data;
      state.pagination.currentPage = examinations.meta.current_page;
      state.pagination.total = examinations.meta.last_page;
      state.packages = packages.data;
      state.packagePagination.currentPage = packages.meta.current_page;
      state.packagePagination.total = packages.meta.last_page;
      state.bundle = doctorBundle;
    },
    setDoctorPackages(state, { doctorPackages }) {
      state.doctorPackages = doctorPackages.data;
      state.doctorPackagePagination.currentPage =
        doctorPackages.meta.current_page;
      state.doctorPackagePagination.total = doctorPackages.meta.last_page;
      state.doctorPackagePagination.totalEleements = doctorPackages.meta.total;
    },
    setPopularExaminations(state, examinations) {
      state.popularExaminations = examinations;
    },
    setExaminationCategories(state, categories) {
      state.examinationsCategories = categories;
    },
    setPackagesCategories(state, categories) {
      state.packagesCategories = categories;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSearch(state, search) {
      state.search = search;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentPackagePage(state, currentPage) {
      state.packagePagination.currentPage = currentPage;
    },
    setCurrentDoctorPackagePage(state, currentPage) {
      state.doctorPackagePagination.currentPage = currentPage;
    },
    setNursingServices(state, services) {
      state.services.covid_nursing_service = services[0];
      state.services.nursing_service = services[1];
    },
    setDoctorBundle(state, bundle) {
      state.doctorBundle = bundle;
    },
    clearAll(state) {
      state.examinations = [];
      state.packages = [];
      state.popularExaminations = [];
      state.errors = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
      state.packagePagination.currentPage = 1;
      state.packagePagination.total = 1;
      state.doctorPackagePagination.currentPage = 1;
      state.doctorPackagePagination.total = 1;
      state.search = "";
    },
  },
  actions: {
    async fetchPopularExaminations({ commit }, { facilityId, categoryId }) {
      const { data } = await http.get("/services/popular/examinations", {
        params: {
          facility_id: facilityId,
          category_id: categoryId,
        },
      });

      commit("setPopularExaminations", data);
    },
    async fetchExaminationsCategories({ commit }, { facilityId, categoryId }) {
      const { data } = await http.get("/categories/examinations", {
        params: {
          is_most_popular: true,
          facility_id: facilityId,
          category_id: categoryId,
        },
      });

      commit("setExaminationCategories", data);
    },
    async fetchPackagesCategories({ commit }, { facilityId, categoryId }) {
      const { data } = await http.get("/categories/packages", {
        params: {
          is_most_popular: true,
          facility_id: facilityId,
          category_id: categoryId,
        },
      });

      commit("setPackagesCategories", data);
    },
    async fetchNursingServices({ commit }, facilityId) {
      const { data } = await http.get("/services/nursing-services", {
        params: {
          facility_id: facilityId,
        },
      });

      commit("setNursingServices", [
        data.covid_nursing_service,
        data.nursing_service,
      ]);
      commit(
        "cart/setNursingServices",
        [data.covid_nursing_service, data.nursing_service],
        {
          root: true,
        }
      );
    },
    async fetchExaminations(
      { commit, state, rootGetters },
      { id, searched, categoryId, facilityId } = {
        id: null,
        searched: false,
        categoryId: null,
        facilityId: null,
      }
    ) {
      const { data } = await http.get(
        id ? `referrals/get-examinations/${id}` : "referrals/get-examinations",
        {
          params: {
            page: state.pagination.currentPage,
            search: state.search,
            searched: searched,
            package_page: state.packagePagination.currentPage,
            doctor_package_page: state.doctorPackagePagination.currentPage,
            category_id: categoryId,
            facility_id: facilityId,
            isBundle: rootGetters["cart/getIsSelectedBundles"],
          },
        }
      );
      commit("setExaminations", data);
      const response = await http.get("referrals/get-doctor-packages", {
        params: {
          doctor_package_page: state.doctorPackagePagination.currentPage,
          timestamp: Date.now(),
        },
      });
      commit("setDoctorPackages", response.data);
      commit("cart/initAdditionalServices", null, {
        root: true,
      });
      commit(
        "cart/setPriceAndCommission",
        rootGetters["user/getCurrentUserCommissionPercent"],
        {
          root: true,
        }
      );
    },
    async fetchDoctorPackage({ commit }, id) {
      const data = await http.get(`doctor/package/show/${id}`);
      commit("setDoctorBundle", data.data.bundle);
    },
    async setDoctorPackageAction({ commit }, name) {
      await http
        .post("/doctor/package/store", {
          name,
        })
        .then(({ data }) => {
          commit("setDoctorBundle", data.bundle);
          commit("clearErrors");
          store.commit("modals/closeModal", "createNewOwnPackage");
          store.commit("modals/closeModal", "createOwnPackage");
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
        });
    },
    async setDoctorPackageNameAction({ commit }, packageData) {
      await http
        .put(`/doctor/package/update/${packageData.id}`, {
          name: packageData.name,
        })
        .then(({ data }) => {
          commit("setDoctorBundle", data.bundle);
          commit("clearErrors");
          store.commit("modals/closeModal", "updateNewOwnPackage");
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
        });
    },
  },
};
