export default {
  namespaced: true,
  state: {
    statuses: [],
    accountStatuses: [
      {
        label: "filters.status",
        value: null,
      },
      {
        label: "doctor_status.active",
        value: "active",
      },
      {
        label: "doctor_status.inactive",
        value: "inactive",
      },
      {
        label: "doctor_status.blocked",
        value: "blocked",
      },
      {
        label: `doctor_status.all`,
        value: "all",
      },
    ],
    patientStatuses: [
      {
        label: "filters.status",
        value: null,
      },
      {
        label: "doctor_status.active",
        value: "active",
      },
      {
        label: "doctor_status.blocked",
        value: "blocked",
      },
      {
        label: `doctor_status.all`,
        value: "all",
      },
    ],
    invoiceStatuses: [
      {
        label: "filters.status",
        value: null,
      },
      {
        label: "admin.invoices.statuses.new",
        value: "new",
      },
      {
        label: "admin.invoices.statuses.paid",
        value: "paid",
      },
      // {
      //   label: "admin.invoices.statuses.to_send",
      //   value: "to_send",
      // },
      {
        label: `admin.invoices.statuses.all`,
        value: "all",
      },
    ],
    discounts: [
      {
        label: "filters.patient_discount_percent",
        value: null,
      },
      {
        label: "patient_discount_percent.zero",
        value: "0",
      },
      {
        label: "patient_discount_percent.five",
        value: "5",
      },
      {
        label: "patient_discount_percent.ten",
        value: "10",
      },
      {
        label: `patient_discount_percent.fifteen`,
        value: "15",
      },
      {
        label: `patient_discount_percent.all`,
        value: "all",
      },
    ],
    referralType: [
      {
        label: "filters.type",
        value: null,
      },
      {
        label: "referral_type.doctor",
        value: "doctor",
      },
      {
        label: "referral_type.sport",
        value: "sport",
      },
      {
        label: "referral_type.home",
        value: "home",
      },
      {
        label: "referral_type.withoutSport",
        value: "withoutSport",
      },
      {
        label: "referral_type.archive",
        value: "archive",
      },
      {
        label: "referral_type.laboratory",
        value: "laboratory",
      },
      {
        label: "referral_type.all",
        value: "all",
      },
    ],
    invoiceClientStatuses: [
      {
        label: "admin.invoices.statuses.to_send",
        value: "to_send",
      },
      {
        label: "admin.invoices.statuses.sent",
        value: "sent",
      },
    ],
  },
  getters: {
    getStatuses: (state) => state.statuses,
    getInvoiceStatuses: (state) => state.invoiceStatuses,
    getReferralType: (state) => state.referralType,
    getStatusId: (state) => (name) =>
      state.statuses.find((status) => status.value === name),
    getAccountStatuses: (state) => state.accountStatuses,
    getPatientStatuses: (state) => state.patientStatuses,
    getDoctorStatusId: (state) => (name) =>
      state.accountStatuses.find((status) => status.value === name),
    getPatientStatusId: (state) => (name) =>
      state.patientStatuses.find((status) => status.value === name),
    getInvoiceStatusId: (state) => (name) =>
      state.invoiceStatuses.find((status) => status.value === name),
    getInvoiceClientStatusId: (state) => (name) =>
      state.invoiceClientStatuses.find((status) => status.value === name),
    getReferralTypeId: (state) => (name) =>
      state.referralType.find((status) => status.value === name),
    getDiscounts: (state) => state.discounts,
    getDiscountId: (state) => (name) =>
      state.discounts.find((discount) => discount.value === name),
    getInvoiceClientStatuses: (state) => state.invoiceClientStatuses,
  },
  mutations: {
    setStatuses(state, statuses) {
      state.statuses = statuses;
    },
  },
  actions: {
    initStatuses({ commit, rootGetters }) {
      let userRole = rootGetters["user/getCurrentUserRole"];
      if (userRole === "admin") userRole = "doctor";
      commit("setStatuses", [
        {
          label: "filters.status",
          value: null,
        },
        {
          label: `referral_status.${userRole}.issued`,
          value: "issued",
        },
        {
          label: `referral_status.${userRole}.paid`,
          value: "paid",
        },
        {
          label: `referral_status.${userRole}.canceled`,
          value: "canceled",
        },
        {
          label: `referral_status.${userRole}.completed`,
          value: "completed",
        },
        {
          label: `referral_status.${userRole}.all`,
          value: "all",
        },
      ]);
    },
  },
};
