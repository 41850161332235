import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    currentUser: null,
    billingData: null,
    errors: [],
    stats: {
      referrals: {
        issued: 0,
        paid: 0,
        canceled: 0,
        completed: 0,
      },
      commission: 0,
    },
    toPay: 0,
    commission: 0,
  },
  getters: {
    getCurrentUser: (state) => state.currentUser,
    getCurrentUserStats: (state) => state.stats,
    getCurrentUserRole: (state) => state.currentUser.role,
    getCurrentUserAccess: (state) => state.currentUser.access,
    getCurrentUserVerified: (state) => state.currentUser.sms_verified,
    getCurrentUserFacility: (state) => state.currentUser.has_selected_facility,
    getCurrentUserSelectedFacility: (state) =>
      state.currentUser.selected_facility,
    getCurrentUserStatus: (state) => state.currentUser.status,
    getCurrentUserCommissionPercent: (state) =>
      state.currentUser.commission_percent,
    getCurrentUserBillingData: (state) => state.billingData,
    getErrors: (state) => state.errors,
    getToPay: (state) => state.toPay,
    getCommission: (state) => state.commission,
    getHasBillingData: (state) => state.currentUser.has_billing_data,
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setUserBillingData(state, billingData) {
      state.billingData = billingData;
    },
    setCurrentUserStats(state, stats) {
      state.stats = stats;
    },
    setCurrentUserCommissionPercent(state, percent) {
      state.currentUser.commission_percent = percent;
    },
    setUserStats(state, stats) {
      state.stats = stats;
    },
    setUserToPay(state, toPay) {
      state.toPay = toPay;
    },
    setUserCommission(state, commission) {
      state.commission = commission;
    },
    resetCurrentUser(state) {
      state.currentUser = null;
      state.billingData = null;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  actions: {
    async fetchCurrentUser({ commit }) {
      const response = await http.get("/user");
      const user = response.data.user;
      commit("setCurrentUser", user);
      commit("setUserCommission", user.commission_percent);
      commit("billingData/setCheckAll", false, { root: true });
      if (user.billing_data) {
        commit("setUserBillingData", user.billing_data);
        commit("billingData/setBillingData", user.billing_data, { root: true });
      }
      if (user.role === "doctor") {
        commit("setUserStats", response.data.stats);
        commit(
          "cart/setPatientDiscountPercent",
          user.patient_discount_percent,
          { root: true }
        );
      }
    },
    async updateCurrentUser({ commit }, user) {
      await http
        .post(`/profile/update`, user)
        .then((response) => {
          const user = response.data.user;
          commit("setCurrentUser", user);
          commit("clearErrors");
          store.commit("modals/closeModal", "updateUserData");
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: "messages.edit.failure",
            duration: 5000,
          });
        });
    },
    async fetchUserStats(
      { commit },
      { time = "current_month", date_range = null }
    ) {
      await http
        .get("/doctor/stats", {
          params: {
            time: time,
            date_range: date_range,
          },
        })
        .then(({ data }) => {
          commit("setCurrentUserStats", data.stats);
        });
    },
  },
};
