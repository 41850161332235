import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    guestUsers: [],
    guestUsersCount: null,
    errors: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    statuses: [
      {
        id: 1,
        name: "active",
      },
      {
        id: 3,
        name: "blocked",
      },
    ],
    search: "",
    currentGuestUsers: {
      first_name: "",
      last_name: "",
      phone_number: "",
      identity_card: "",
      pesel: "",
      birth_date: "",
      day: "",
      month: "",
      year: "",
      gender: "",
    },
    currentGuestUsersStats: {
      referrals: {
        issued: 0,
        paid: 0,
        canceled: 0,
        completed: 0,
      },
      total_price: 0,
    },
  },
  getters: {
    getGuestUsers: (state) => state.guestUsers,
    getGuestUsersCount: (state) => state.guestUsersCount,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getSearch: (state) => state.search,
    getCurrentPatient: (state) => state.currentGuestUser,
    getCurrentGuestUsersStats: (state) => state.currentGuestUserStats,
    getStatuses: (state) => state.statuses,
    getStatusById: (state) => (id) => {
      return state.statuses.find((status) => status.id === id);
    },
    getGuestUserById: (state) => (id) => {
      return state.patients.find((patient) => patient.id === id);
    },
  },
  mutations: {
    setGuestUsers(state, guestUsers) {
      state.guestUsers = guestUsers.data;
      state.pagination.currentPage = guestUsers.meta.current_page;
      state.pagination.total = guestUsers.meta.last_page;
    },
    setGuestUsersCount(state, guestUsersCount) {
      state.guestUsersCount = guestUsersCount;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    addError(state, { key, error }) {
      state.errors = {
        ...state.errors,
        [key]: error,
      };
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSearch(state, search) {
      state.search = search;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentPatient(state, currentPatient) {
      state.currentPatient = currentPatient;
    },
    setCurrentPatientValue(state, currentPatient) {
      Object.assign(state.currentPatient, currentPatient);
    },
    setBirthDate(state, currentPatient) {
      Object.assign(state.currentPatient, currentPatient);
      const { year, day, month } = state.currentPatient;
      state.currentPatient.birth_date = `${day}.${month}.${year}`;
    },
    clearCurrentPatient(state) {
      Object.keys(state.currentPatient).forEach(
        (key) => (state.currentPatient[key] = "")
      );
    },
    clearSearch(state) {
      state.search = "";
    },
    clearGuestUsers(state) {
      state.patients = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
  },
  actions: {
    async fetchGuestUsers({ commit, state }, { filters, sortBy }) {
      const { data } = await http.get("admin/guest-users", {
        params: {
          page: state.pagination.currentPage,
          search: state.search,
          ...filters,
          ...sortBy,
        },
      });
      const guestUsers = data.guestUsers;
      commit("setGuestUsers", guestUsers);
      commit("setGuestUsersCount", guestUsers.meta.total);
    },
  },
};
